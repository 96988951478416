import React, { useEffect } from 'react';
import { useStats } from '../contexts/Stats';
import { useSolana } from '../contexts/SolanaInteractions';

const TradeStatsUpdater = () => {
    const { setPlatformStats, setUserStats } = useStats();
    const { connection } = useSolana();

    useEffect(() => {
        const fetchStats = () => {
            if (connection) {
                // Fetch platform stats
                const totalTrades = parseInt(localStorage.getItem('totalTrades')) || 0;
                const totalVolume = parseFloat(localStorage.getItem('totalVolume')) || 0.0;

                setPlatformStats({
                    totalTrades,
                    totalVolume,
                });

                // Fetch user stats
                const userTrades = parseInt(localStorage.getItem('userTrades')) || 0;
                const userVolume = parseFloat(localStorage.getItem('userVolume')) || 0.0;

                setUserStats({
                    userTrades,
                    userVolume,
                });
            }
        };

        // Fetch initial stats
        fetchStats();

        // Set interval to fetch stats every 30 seconds
        const intervalId = setInterval(fetchStats, 30000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [connection, setPlatformStats, setUserStats]);

    return null; // This component does not render anything
};

export default TradeStatsUpdater;
