import React, { createContext, useContext, useEffect, useState } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import TradeStatsUpdater from '../components/TradeStatsUpdater';
import { PublicKey, Keypair } from '@solana/web3.js';
import bs58 from 'bs58';

const SolanaContext = createContext();

export const useSolana = () => useContext(SolanaContext);

export const SolanaInteractionsProvider = ({ children }) => {
    const { connection } = useConnection();
    const { publicKey } = useWallet();
    const [balance, setBalance] = useState(null);
    const [tokenBalance, setTokenBalance] = useState(null);
    const [walletPublicKey, setWalletPublicKey] = useState(null);
    const [keyPair, setKeyPair] = useState(null);


    const processPrivateKey = async (privateKeyStr) => {
        console.log(`Received Private Key = ${privateKeyStr}`);
        try {
            let privateKeyBytes;
    
            // Check if the private key is in Base58 format
            if (/^[1-9A-HJ-NP-Za-km-z]+$/.test(privateKeyStr)) {
                // Decode Base58 formatted private key
                privateKeyBytes = bs58.decode(privateKeyStr);
                if (privateKeyBytes.length !== 64) {
                    throw new Error('Invalid secret key length. Expected 64 bytes.');
                }
            } else {
                // Process the private key as a comma-separated string of numbers
                const privateKeyArray = privateKeyStr.replace(/[[\]]/g, '').split(',').map(num => parseInt(num.trim(), 10));
                if (privateKeyArray.length !== 64) {
                    throw new Error('Invalid secret key length. Expected 64 bytes.');
                }
                privateKeyBytes = new Uint8Array(privateKeyArray);
            }
    
            console.log(`Uint8Array = ${privateKeyBytes}`);
            const base58Encoded = bs58.encode(privateKeyBytes);
            console.log(`Base58 Encoded Private Key: ${base58Encoded}`);
    
            const tempkeypair = Keypair.fromSecretKey(privateKeyBytes);
            setKeyPair(tempkeypair);
            const publicKey = tempkeypair.publicKey;
            setWalletPublicKey(publicKey.toString());
    
            console.log(`Keypair publicKey = ${publicKey.toString()}`);
            console.log(`Keypair secretKey = `, { tempkeypair });
    
            if (connection) {
                const balanceResult = await connection.getBalance(publicKey);
                const balance = balanceResult / 1e9; // Convert from lamports to SOL
                console.log(`Balance = ${balance} SOL`);
    
                setBalance(balance);
                return { success: true, balance, publicKey: publicKey.toString() };
            } else {
                console.error('No connection available');
                return { success: false, balance: 0, publicKey: null };
            }
        } catch (error) {
            console.error('Error in processing private key:', error);
            return { success: false, balance: 0, publicKey: null };
        }
    };

    const fetchConnectedWalletBalance = async () => {
        if (publicKey && connection) {
            try {
                const publicKeyString = publicKey.toBase58();
                new PublicKey(publicKeyString);

                console.log("Fetching balance for: ", publicKeyString);
                const balance = await connection.getBalance(publicKey);
                console.log("Balance fetched: ", balance);
                setBalance(balance / 1e9); // Convert from lamports to SOL
            } catch (error) {
                console.error("Error fetching balance: ", error);
                setBalance(null); // Reset balance on error
            }
        } else {
            console.log("No public key or connection available");
        }
    };

    const fetchTokenBalanceConnectedWallet = async (tokenAddr) => {
        try {
            const result = await connection.getTokenAccountsByOwner(publicKey, { mint: new PublicKey(tokenAddr) });
            const info = await connection.getTokenAccountBalance(result.value[0].pubkey);
            if (info.value.uiAmount == null) throw new Error('No balance found');
            setTokenBalance(info.value.uiAmount);
            return info.value.uiAmount;
        } catch (e) {
            console.error("Error fetching token balance", e);
            return 0;
        }
    };

    const fetchTokenBalancePrivateKey = async (tokenAddr) => {
        try {
            console.log("Fetching token balance for token address:", tokenAddr);
            if (!keyPair || !keyPair.publicKey) {
                throw new Error("Keypair is not initialized");
            }
    
            const ownerPublicKey = keyPair.publicKey;
            const mintPublicKey = new PublicKey(tokenAddr);
    
            console.log("Owner Public Key:", ownerPublicKey.toBase58());
            console.log("Mint Public Key:", mintPublicKey.toBase58());
    
            // Fetch token accounts by owner
            const tokenAccounts = await connection.getTokenAccountsByOwner(ownerPublicKey, {
                mint: mintPublicKey
            });
    
            console.log("Token Accounts:", tokenAccounts);
    
            if (tokenAccounts.value.length === 0) {
                throw new Error('No token accounts found for the provided mint address');
            }
    
            // Fetch token account balance
            const tokenAccountPubkey = tokenAccounts.value[0].pubkey;
            console.log("Token Account Pubkey:", tokenAccountPubkey.toBase58());
    
            const tokenAccountBalance = await connection.getTokenAccountBalance(tokenAccountPubkey);
    
            console.log("Token Account Balance:", tokenAccountBalance);
    
            if (tokenAccountBalance.value.uiAmount == null) {
                throw new Error('No balance found');
            }
    
            const tokenBalance = tokenAccountBalance.value.uiAmount;
            setTokenBalance(tokenBalance);
            return tokenBalance;
        } catch (e) {
            console.error("Error fetching token balance:", e);
            return 0;
        }
    };
    
    

    useEffect(() => {
        if (publicKey && connection && balance === null) {
            fetchConnectedWalletBalance();
        }
    }, [publicKey, connection, balance]);

    const value = {
        balance,
        tokenBalance,
        walletPublicKey,
        keyPair,
        connection,
        processPrivateKey,
        fetchConnectedWalletBalance,
        fetchTokenBalanceConnectedWallet,
        fetchTokenBalancePrivateKey,
        setTokenBalance,
    };

    

    return (
        <SolanaContext.Provider value={value}>
            {children}
            <TradeStatsUpdater />
        </SolanaContext.Provider>
    );
};
