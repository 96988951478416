import React, { useState, useEffect } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { FaTwitter, FaTelegram, FaInternetExplorer } from 'react-icons/fa';
import { useSolana } from '../contexts/SolanaInteractions';
import { useTransaction, TransactionProvider } from '../contexts/TransactionHelper';
import Trader from './Trader';

const TokenInfo = ({ pKey, setPKey }) => {
    const { balance, fetchConnectedWalletBalance, processPrivateKey, walletPublicKey, keyPair } = useSolana();
    const { transactionStatus, buyToken, sellToken, swapTokens } = useTransaction();
    const [address, setAddress] = useState('');
    const [tokenInfo, setTokenInfo] = useState(null);
    const [tokenName, setTokenName] = useState('');
    const [tokenDescription, setTokenDescription] = useState('');
    const [tokenSymbol, setTokenSymbol] = useState('');
    const [tokenStandard, setTokenStandard] = useState('');
    const [tokenImage, setTokenImage] = useState('');
    const [ownerAddress, setOwnerAddress] = useState('');
    const [holderCount, setHolderCount] = useState(0);
    const [twitter, setTwitter] = useState('');
    const [telegram, setTelegram] = useState('');
    const [website, setWebsite] = useState('');
    const [interval, setInterval] = useState('');
    const [solPerBuy, setSolPerBuy] = useState('');
    const [duration, setDuration] = useState('');
    const [slippage, setSlippage] = useState('');
    const [loading, setLoading] = useState(false);
    const [trueConnect, setTrueConnect] = useState('');
    const [output, setOutput] = useState('');
    
    const heliusConnection = new Connection(process.env.REACT_APP_HELIUS_RPC);

    const fetchTokenInfo = async (address) => {
        setLoading(true);
        setTrueConnect(trueConnect)
        try {
            const tokenPublicKey = new PublicKey(address);
            const tokenAccountInfo = await heliusConnection.getParsedAccountInfo(tokenPublicKey);

            if (tokenAccountInfo.value) {
                const info = tokenAccountInfo.value.data.parsed.info;
                setTokenInfo(info);

                const largestAccounts = await heliusConnection.getTokenLargestAccounts(tokenPublicKey);
                setHolderCount(largestAccounts.value.length);

                setOwnerAddress(info.freezeAuthority ? info.freezeAuthority : 'Unknown');

                const response = await fetch(process.env.REACT_APP_HELIUS_RPC, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        jsonrpc: '2.0',
                        id: 'my-id',
                        method: 'getAsset',
                        params: {
                            id: tokenPublicKey.toBase58(),
                        },
                    }),
                });

                const { result } = await response.json();
                setTokenName(result.content.metadata.name);
                setTokenDescription(result.content.metadata.description);
                setTokenSymbol(result.content.metadata.symbol);
                setTokenStandard(result.content.metadata.token_standard);

                let tokenImage = result.content.links?.image || result.content.files?.[0]?.uri || '';

                const jsonUri = result.content.json_uri;
                if (jsonUri) {
                    try {
                        const corsEnabledUri = jsonUri.replace('cf-ipfs.com', 'ipfs.io');
                        const jsonResponse = await fetch(corsEnabledUri, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            redirect: 'follow',
                        });

                        if (!jsonResponse.ok) throw new Error(`HTTP error! status: ${jsonResponse.status}`);

                        const jsonData = await jsonResponse.json();
                        setTwitter(jsonData.twitter || '');
                        setTelegram(jsonData.telegram || '');
                        setWebsite(jsonData.website || '');

                        if (!tokenImage && jsonData.image) tokenImage = jsonData.image;
                    } catch (jsonError) {
                        console.error('Error fetching JSON URI data:', jsonError);
                    }
                }

                const corsEnabledImage = tokenImage.replace('cf-ipfs.com', 'ipfs.io');
                setTokenImage(corsEnabledImage);
            } else {
                resetTokenInfo();
            }
        } catch (error) {
            console.error('Error fetching token info:', error);
            resetTokenInfo();
        }
        setLoading(false);
    };

    const handlePrivateKeyChange = async (e) => {
        const key = e.target.value;
        setPKey(key);
        await processPrivateKey(key);
    };

    const resetTokenInfo = () => {
        setTokenInfo(null);
        setTokenName('');
        setTokenDescription('');
        setTokenSymbol('');
        setTokenStandard('');
        setTokenImage('');
        setTwitter('');
        setTelegram('');
        setWebsite('');
        setOwnerAddress('');
        setHolderCount(0);
    };

    useEffect(() => {
        if (address) {
            try {
                new PublicKey(address);
                fetchTokenInfo(address);
            } catch (error) {
                console.error('Invalid public key input', error);
            }
        }
    }, [address]);

    const handleStart = (interval, solPerBuy, duration, slippage) => {
        console.log('Bot started with:', { interval, solPerBuy, duration, slippage });
    };

    const handleStop = () => {
        console.log('Bot stopped');
    };

    return (
        <div className="container">
            <div className="address-input">
                <input
                    id="address"
                    type="text"
                    placeholder="Enter Contract Address"
                    value={address}
                    onChange={(e) => {
                        setAddress(e.target.value);
                        if (e.target.value) {
                            fetchTokenInfo(e.target.value);
                        }
                    }}
                />
            </div>
            {loading ? (
                <div className="loading-spinner"></div>
            ) : (
                address && (
                    <div className="tables-container">
                        <div className="input-table-container">
                            <table className="input-table">
                                <tbody>
                                    <tr>
                                        <td>Interval (seconds between buys)</td>
                                        <td>
                                            <input
                                                type="number"
                                                value={interval}
                                                onChange={(e) => setInterval(e.target.value)}
                                                className="no-arrows"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>SOL per Buy</td>
                                        <td>
                                            <input
                                                type="number"
                                                value={solPerBuy}
                                                onChange={(e) => setSolPerBuy(e.target.value)}
                                                className="no-arrows"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Duration (minutes before stopping the bot)</td>
                                        <td>
                                            <input
                                                type="number"
                                                value={duration}
                                                onChange={(e) => setDuration(e.target.value)}
                                                className="no-arrows"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Slippage %</td>
                                        <td>
                                            <input
                                                type="number"
                                                value={slippage}
                                                onChange={(e) => setSlippage(e.target.value)}
                                                className="no-arrows"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <div className="button-container">
                                                <input
                                                    type="text"
                                                    placeholder="Private Key - Required to automate trading - We do not store the key"
                                                    value={pKey}
                                                    onChange={handlePrivateKeyChange}
                                                    className="no-arrows"
                                                />
                                                <TransactionProvider setOutput={setOutput}>
                                                    <Trader
                                                        address={address}
                                                        interval={interval}
                                                        solPerBuy={solPerBuy}
                                                        duration={duration}
                                                        slippage={slippage}
                                                        tokenSymbol={tokenSymbol}
                                                        onStart={handleStart}
                                                        onStop={handleStop}
                                                        setOutput={setOutput}
                                                        keyPair={keyPair}
                                                        pKey={pKey}
                                                    />
                                                </TransactionProvider>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                        <div
                                            className="styled-textarea"
                                            dangerouslySetInnerHTML={{ __html: output }}
                                        />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {tokenInfo && (
                            <div className="token-info">
                                <table className="token-table">
                                    <tbody>
                                        {tokenName && (
                                            <tr>
                                                <td>Project Name</td>
                                                <td>
                                                    <div className="project-info">
                                                        <span>{tokenName}</span>
                                                        <div className="social-links">
                                                            {twitter && (
                                                                <a href={twitter} target="_blank" rel="noopener noreferrer">
                                                                    <FaTwitter />
                                                                </a>
                                                            )}
                                                            {telegram && (
                                                                <a href={telegram} target="_blank" rel="noopener noreferrer">
                                                                    <FaTelegram />
                                                                </a>
                                                            )}
                                                            {website && (
                                                                <a href={website} target="_blank" rel="noopener noreferrer">
                                                                    <FaInternetExplorer />
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        {tokenDescription && (
                                            <tr>
                                                <td>Description</td>
                                                <td>{tokenDescription}</td>
                                            </tr>
                                        )}
                                        {tokenSymbol && (
                                            <tr>
                                                <td>Symbol</td>
                                                <td>{tokenSymbol}</td>
                                            </tr>
                                        )}
                                        {tokenImage && (
                                            <tr>
                                                <td>Image</td>
                                                <td>
                                                    <img src={tokenImage} alt="Token" width="50" />
                                                </td>
                                            </tr>
                                        )}
                                        {holderCount !== 0 && (
                                            <tr>
                                                <td>Holder Count</td>
                                                <td>{holderCount}</td>
                                            </tr>
                                        )}
                                        {tokenInfo && tokenInfo.supply && (
                                            <tr>
                                                <td>Supply</td>
                                                <td>{tokenInfo.supply}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                )
            )}
        </div>
    );
};

export default TokenInfo;
