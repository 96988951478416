import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { WalletContextProvider } from './contexts/WalletContext';
import { SolanaInteractionsProvider } from './contexts/SolanaInteractions';
import { StatsProvider } from './contexts/Stats';
import { TransactionProvider } from './contexts/TransactionHelper';
import TokenInfo from './components/TokenInfo';
import Header from './components/Header';
import Footer from './components/Footer';
import AcceptDialog from './components/AcceptDialog';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import './App.css';
import Links from './components/Links';
import LiveChart from './components/LiveChart';
import backlinksData from './links.json'; // Import the JSON data

function App() {
    const [pKey, setPKey] = useState('');
    const [output, setOutput] = useState('');
    const [isAccepted, setIsAccepted] = useState(false);

    useEffect(() => {
        const accepted = localStorage.getItem('accepted');
        if (accepted === 'true') {
            setIsAccepted(true);
        }

        // Example: Add structured data to the head of the document
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.innerHTML = JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "mainEntity": {
                "@type": "ItemList",
                "itemListElement": backlinksData.map((link, index) => ({
                    "@type": "ListItem",
                    "position": index + 1,
                    "url": link["url"]
                }))
            }
        });
        document.head.appendChild(script);

        // Clean up the script element when the component unmounts
        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <WalletContextProvider>
            <StatsProvider>
                <SolanaInteractionsProvider >
                    <TransactionProvider setOutput={setOutput} >
                            <div className="App">
                                <Header />
                                <Analytics />
                                <SpeedInsights />
                                <Router>
                                <div className="App-main">
                                    <h1>Welcome to PumpNow.Fun! Cheapest Web Based Bump Bot For Pump.fun</h1>
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={isAccepted ? (
                                                <TokenInfo pKey={pKey} setPKey={setPKey} />
                                            ) : (
                                                <AcceptDialog onAccept={() => setIsAccepted(true)} />
                                            )}
                                        />
                                        <Route path="/links" element={<Links />} />
                                        <Route path="/live-chart" element={<LiveChart />} />
                                    </Routes>
                                </div>
                                <Footer />
                        </Router>
                        </div>
                    </TransactionProvider>
                </SolanaInteractionsProvider>
            </StatsProvider>
        </WalletContextProvider>
    );
}

export default App;
