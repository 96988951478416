import React, { createContext, useState, useContext } from 'react';

const StatsContext = createContext();

export const useStats = () => {
    return useContext(StatsContext);
};

export const StatsProvider = ({ children }) => {
    const [platformStats, setPlatformStats] = useState(null);
    const [userStats, setUserStats] = useState(null);

    return (
        <StatsContext.Provider value={{ userStats, setPlatformStats, setUserStats }}>
            {children}
        </StatsContext.Provider>
    );
};
