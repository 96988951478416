import React, { useEffect, useRef, useState } from 'react';
import { createChart, ColorType, CrosshairMode } from 'lightweight-charts';

// Function to generate random candlestick and volume data
const generateRandomCandlestickData = (startDate, numPoints) => {
  const data = [];
  const volumeData = [];
  let time = Math.floor(startDate.getTime() / 1000);
  let open = 100;

  for (let i = 0; i < numPoints; i++) {
    const close = open + (Math.random() - 0.5) * 10;
    const high = Math.max(open, close) + Math.random() * 5;
    const low = Math.min(open, close) - Math.random() * 5;
    const volume = Math.floor(Math.random() * 1000);

    data.push({
      time,
      open,
      high,
      low,
      close,
    });

    volumeData.push({
      time,
      value: volume,
      color: close > open ? 'rgba(54, 116, 217, 0.4)' : 'rgba(255, 50, 85, 0.4)',
    });

    open = close;
    time += 3; // Increment by 3 seconds
  }

  return { data, volumeData };
};

const LiveChart = () => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const mainSeriesRef = useRef(null);
  const volumeSeriesRef = useRef(null);
  const [lastDataPoint, setLastDataPoint] = useState(null);

  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 400,
      layout: {
        background: { type: ColorType.Solid, color: '#222' },
        textColor: '#C3BCDB',
      },
      grid: {
        vertLines: { color: '#444' },
        horzLines: { color: '#444' },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
        vertLine: {
          width: 1,
          color: '#9B7DFF',
          labelBackgroundColor: '#9B7DFF',
        },
        horzLine: {
          color: '#9B7DFF',
          labelBackgroundColor: '#9B7DFF',
        },
      },
      rightPriceScale: {
        borderColor: '#71649C',
      },
    });

    const currentLocale = window.navigator.languages[0];
    const myPriceFormatter = Intl.NumberFormat(currentLocale, {
      style: 'currency',
      currency: 'USD',
    }).format;

    chart.applyOptions({
      localization: {
        priceFormatter: myPriceFormatter,
      },
    });

    const { data, volumeData } = generateRandomCandlestickData(new Date(), 50);
    setLastDataPoint(data[data.length - 1]);

    const mainSeries = chart.addCandlestickSeries({
      upColor: 'rgb(54, 116, 217)',
      downColor: 'rgb(225, 50, 85)',
      wickUpColor: 'rgb(54, 116, 217)',
      wickDownColor: 'rgb(225, 50, 85)',
      borderVisible: false,
    });
    mainSeries.setData(data);

    const volumeSeries = chart.addHistogramSeries({
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: '', // set as an overlay by setting a blank priceScaleId
    });
    volumeSeries.setData(volumeData);

    volumeSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
      borderColor: '#71649C',
    });

    chartRef.current = chart;
    mainSeriesRef.current = mainSeries;
    volumeSeriesRef.current = volumeSeries;
    chart.timeScale().applyOptions({
      rightOffset: 12,
      barSpacing: 3,
      minimumHeight: 50,
    });
    chart.timeScale().fitContent();

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    // Tooltip element
    const toolTip = document.createElement('div');
    toolTip.className = 'tooltip';
    document.body.appendChild(toolTip);

    // Tooltip styling
    toolTip.style.display = 'none';
    toolTip.style.position = 'absolute';
    toolTip.style.background = '#1e1e1e';
    toolTip.style.color = 'white';
    toolTip.style.padding = '5px';
    toolTip.style.borderRadius = '5px';
    toolTip.style.pointerEvents = 'none';

    chart.subscribeCrosshairMove((param) => {
        if (
          !param ||
          !param.time ||
          param.point.x < 0 ||
          param.point.x > chartContainerRef.current.clientWidth ||
          param.point.y < 0 ||
          param.point.y > chartContainerRef.current.clientHeight
        ) {
          toolTip.style.display = 'none';
          return;
        }
  
        const price = param.seriesData.get(mainSeries);
        if (!price) {
          toolTip.style.display = 'none';
          return;
        }
  
        toolTip.style.display = 'block';
        const dateStr = new Date(param.time * 1000).toLocaleDateString();
        toolTip.innerHTML = `<div style="font-size: 12px; margin: 4px 0; padding: 5px">${dateStr}</div>
                          <div style="font-size: 12px; margin: 4px 0; padding: 5px">Open: ${price.open.toFixed(2)}</div>
                          <div style="font-size: 12px; margin: 4px 0; padding: 5px">High: ${price.high.toFixed(2)}</div>
                          <div style="font-size: 12px; margin: 4px 0; padding: 5px">Low: ${price.low.toFixed(2)}</div>
                          <div style="font-size: 12px; margin: 4px 0; padding: 5px">Close: ${price.close.toFixed(2)}</div>`;

        const toolTipWidth = toolTip.getBoundingClientRect().width;
        const toolTipHeight = toolTip.getBoundingClientRect().height;
        const left = param.point.x - toolTipWidth / 2 + chartContainerRef.current.getBoundingClientRect().left;
        const top = param.point.y - toolTipHeight - 10 + chartContainerRef.current.getBoundingClientRect().top;

        toolTip.style.left = `${left}px`;
        toolTip.style.top = `${top}px`;
    });
                   

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
      document.body.removeChild(toolTip);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const mainSeries = mainSeriesRef.current;
      const volumeSeries = volumeSeriesRef.current;
      const lastTime = lastDataPoint.time;
      const newTime = lastTime + 3; // Increment by 3 seconds

      const open = lastDataPoint.close;
      const close = open + (Math.random() - 0.5) * 10;
      const high = Math.max(open, close) + Math.random() * 5;
      const low = Math.min(open, close) - Math.random() * 5;
      const volume = Math.floor(Math.random() * 1000);

      const newData = { time: newTime, open, high, low, close };
      const newVolumeData = {
        time: newTime,
        value: volume,
        color: close > open ? 'rgba(54, 116, 217, 0.4)' : 'rgba(255, 82, 82, 0.4)',
      };

      mainSeries.update(newData);
      volumeSeries.update(newVolumeData);

      setLastDataPoint(newData);
    }, 3000); // Update every 3 seconds

    return () => {
      clearInterval(interval);
    };
  }, [lastDataPoint]);

  return <div ref={chartContainerRef} className="chart-container" />;
};

export default LiveChart;

