import React from 'react';
import backlinksData from '../links.json'; // Import the JSON data

const Links = () => {
    return (
        <div>
            <h2>Links</h2>
            <ul>
                {backlinksData.map((link, index) => (
                    <li key={index}>
                        <a href={link.url} target="_blank" rel="noopener noreferrer">
                            {link.url}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Links;
