import React, { useEffect } from 'react';
import { useSolana } from '../contexts/SolanaInteractions';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useStats } from '../contexts/Stats';


const Header = () => {
    const { balance, fetchConnectedWalletBalance, connection } = useSolana();
    const { platformStats, userStats } = useStats();

    useEffect(() => {
        if (connection && balance === null) {
            fetchConnectedWalletBalance();
        }
    }, [connection, balance, fetchConnectedWalletBalance]);

    const isWideScreen = window.innerWidth >= 600;

    return (
        <header className="App-header">
            <div className="header-left">
                <img src={`${process.env.PUBLIC_URL}/bump_bot.png`} alt="PumpNow.Fun Logo" className="header-logo" />
            </div>

            {isWideScreen && (
                <div className="header-stats">
                    {/* {platformStats && (
                        <div className="platform-stats">
                            <p>Total Trades: {platformStats.totalTrades}</p>
                            <p>Total Volume: {platformStats.totalVolume} SOL</p>
                        </div>
                    )} */}
                    {userStats && (
                        <div className="user-stats">
                            <p>Your Trades: {userStats.userTrades}</p>
                            <p>Your Volume: {typeof userStats.userVolume === 'number' ? userStats.userVolume.toFixed(2) : userStats.userVolume} SOL</p>

                        </div>
                    )}
                </div>
            )}

            <div className="header-content">
                <div className="balance-container">
                    <p className="balance-text">Balance: {balance !== null ? balance.toFixed(2) : 'N/A'} SOL</p>
                </div>
                <WalletMultiButton />
            </div>


        </header>
    );
};

export default Header;
