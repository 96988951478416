import React, { useState } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Footer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const telegramURL = 'https://t.me/PumpNow_Fun';

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="footer">
      <div className="left-footer">
        <p>© 2024 PumpNow.Fun</p>
      </div>
      <div className="right-footer">
        <p onClick={openModal} style={{ cursor: 'pointer' }}>Terms of Service</p>
        <a href={telegramURL}>Telegram</a>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Terms of Service"
      >
        <h2>Terms of Service</h2>
        <div>

          <h3>1. Acceptance of Terms</h3>
          <p>By accessing or using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you may not use the Service.</p>

          <h3>2. Eligibility</h3>
          <p>You must be at least 18 years old to use the Service. By using the Service, you represent and warrant that you are of legal age to form a binding contract and meet all the eligibility requirements.</p>

          <h3>3. Use of Service</h3>
          <p>You agree to use the Service in compliance with all applicable laws and regulations. You are solely responsible for any trades or transactions made using the Service.</p>

          <h3>4. Fees and Payment</h3>
          <p>The use of the Service may be subject to fees. All fees are non-refundable. You agree to pay all applicable fees and charges associated with your use of the Service.</p>

          <h3>5. Risk Disclosure</h3>
          <p>Trading cryptocurrencies involves significant risk. You acknowledge and agree that you are using the Service at your own risk. PumpNow.Fun does not guarantee any profit or protection against losses.</p>

          <h3>6. Limitation of Liability</h3>
          <p>To the maximum extent permitted by law, PumpNow.Fun shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, use, goodwill, or other intangible losses, resulting from your use of the Service.</p>

          <h3>7. Modifications to the Service and Terms</h3>
          <p>PumpNow.Fun reserves the right to modify or discontinue the Service at any time, with or without notice. We may also revise these Terms from time to time. The most current version of the Terms will be posted on our website. By continuing to use the Service after changes are made, you agree to be bound by the revised Terms.</p>
        </div>
        <button onClick={closeModal}>Accept</button>
      </Modal>
    </div>
  );
};


export default Footer;
