import React, { useState, useEffect, useRef } from 'react';
import { useTransaction } from '../contexts/TransactionHelper';
import { useSolana } from '../contexts/SolanaInteractions';

const Trader = ({ address, interval, solPerBuy, duration, slippage, onStart, onStop, tokenSymbol, setOutput, pKey }) => {
    const {
        balance,
        tokenBalance,
        buyToken,
        sellToken,
        totalSolSpent,
        totalSolEarned,
        totalTokensBought,
        totalTokensSold,
        totalTokenBuys,
        totalTokenSells,
    } = useTransaction();
    
    const { fetchTokenBalancePrivateKey, keyPair, processPrivateKey,  setTokenBalance } = useSolana();

    const [isRunning, setIsRunning] = useState(false);
    const intervalIdRef = useRef(null);
    const durationTimeoutIdRef = useRef(null);
    const [lastTransaction, setLastTransaction] = useState(null); // null, 'buy', 'sell'
    const [isTradeInProgress, setIsTradeInProgress] = useState(false); // Track if a trade is in progress

    useEffect(() => {
        if (isRunning) {
            const id = setInterval(() => {
                if (!isTradeInProgress) { // Check if a trade is already in progress
                    executeTrade();
                }
            }, interval * 1000); // Convert seconds to milliseconds
            intervalIdRef.current = id;

            // Set timeout for stopping the bot after the specified duration
            const durationId = setTimeout(() => {
                handleStop();
            }, duration * 60 * 1000); // Convert minutes to milliseconds
            durationTimeoutIdRef.current = durationId;

            // Clear the interval on cleanup
            return () => {
                clearInterval(id);
                clearTimeout(durationId);
            };
        } else if (intervalIdRef.current !== null) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
    }, [isRunning, interval, duration, isTradeInProgress]);

    const handleStart = () => {
        setOutput(prevOutput => prevOutput + 'Bot started...\n');
        setIsRunning(true);
        onStart(interval, solPerBuy, duration, slippage);

        // Set timeout for stopping the bot after the specified duration
        const durationId = setTimeout(() => {
            handleStop();
        }, duration * 60 * 1000); // Convert minutes to milliseconds
        durationTimeoutIdRef.current = durationId;
    };

    const handleStop = async () => {
        setOutput(prevOutput => prevOutput + 'Bot stopped.\n');
        setOutput(prevOutput => prevOutput + 'Selling remaining tokens...\n');
        setIsRunning(false);
    
        if (intervalIdRef.current !== null) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
        if (durationTimeoutIdRef.current !== null) {
            clearTimeout(durationTimeoutIdRef.current);
            durationTimeoutIdRef.current = null;
        }
        const currentBalance = await fetchTokenBalancePrivateKey(address);
        console.log("Token Balance in Stop Bot: ", currentBalance);
        if (currentBalance > 0) {
            try {
                await new Promise(resolve => setTimeout(resolve, 5000)); // Add a delay before fetching balance
                const lastTokenBalance = await fetchTokenBalancePrivateKey(address);
                console.log("Last token balance:", lastTokenBalance);
                const tokensToSell = lastTokenBalance;
                
                if (tokensToSell > 0) {
                    console.log("Initiating sellToken with tokensToSell:", tokensToSell);
                    await sellToken(tokensToSell, slippage, address);
                    console.log("sellToken completed successfully.");
                } else {
                    console.log("No tokens to sell.");
                    setOutput(prevOutput => prevOutput + 'No tokens to sell.\n');
                }
            } catch (error) {
                console.error("Error during sellToken execution:", error);
                setOutput(prevOutput => prevOutput + `Error during sell: ${error.message}\n`);
            }
        } else {
            console.log("Token balance is zero or undefined.");
            setOutput(prevOutput => prevOutput + 'Token balance is zero or undefined.\n');
        }
    
        onStop();
    };
    


    // Track the number of consecutive transactions of the same type
    let consecutiveBuys = 0;
    let consecutiveSells = 0;

    const executeTrade = async () => {
        if (!isRunning || isTradeInProgress) return; // Prevent running if the bot is stopped or trade is in progress
    
        setIsTradeInProgress(true); // Indicate that a trade is in progress
    
        try {
            // Fetch the latest token balance
            const latestTokenBalance = await fetchTokenBalancePrivateKey(address);
            console.log('Latest token balance:', latestTokenBalance);
    
            // Fetch the latest SOL balance
            const latestSolBalance = await processPrivateKey(pKey); 
            console.log('Latest SOL balance:', latestSolBalance.balance);
            setOutput(prevOutput => prevOutput + `SOL balance: ${latestSolBalance.balance}\n`);
    
            const coinFlip = Math.random() < 0.5;
    
            if (latestTokenBalance === 0) {
                // If token balance is zero, initiate a buy
                console.log('Token balance is zero, initiating buy.');
                setOutput(prevOutput => prevOutput + 'Token balance is zero, initiating buy.\n');
                await buyToken(solPerBuy, slippage, address); // Wait for the buy to complete
                setLastTransaction('buy');
                consecutiveBuys++;
                consecutiveSells = 0;
            } else if (latestSolBalance <= solPerBuy) {
                // If SOL balance is too low, initiate a sell
                console.log(`Balance is too low to buy, current balance: ${latestSolBalance}`);
                setOutput(prevOutput => prevOutput + `Balance is too low to buy, current balance: ${latestSolBalance}\n`);
                await sellToken(latestTokenBalance, slippage, address); // Wait for the sell to complete
                setLastTransaction('sell');
                consecutiveSells++;
                consecutiveBuys = 0;
            } else if (lastTransaction === 'buy') {
                // If the last transaction was a buy, decide whether to buy or sell based on a coin flip
                if (coinFlip && consecutiveBuys < 2) {
                    await buyToken(solPerBuy, slippage, address); // Wait for the buy to complete
                    setLastTransaction('buy');
                    consecutiveBuys++;
                    consecutiveSells = 0;
                } else {
                    await sellToken(latestTokenBalance, slippage, address); // Wait for the sell to complete
                    setLastTransaction('sell');
                    consecutiveSells++;
                    consecutiveBuys = 0;
                }
            } else if (lastTransaction === 'sell') {
                // If the last transaction was a sell, decide whether to buy or sell based on a coin flip
                if (coinFlip && consecutiveSells < 2) {
                    await sellToken(latestTokenBalance, slippage, address); // Wait for the sell to complete
                    setLastTransaction('sell');
                    consecutiveSells++;
                    consecutiveBuys = 0;
                } else {
                    await buyToken(solPerBuy, slippage, address); // Wait for the buy to complete
                    setLastTransaction('buy');
                    consecutiveBuys++;
                    consecutiveSells = 0;
                }
            } else {
                // If no previous transaction, decide whether to buy or sell based on a coin flip
                if (coinFlip) {
                    await buyToken(solPerBuy, slippage, address); // Wait for the buy to complete
                    setLastTransaction('buy');
                    consecutiveBuys++;
                    consecutiveSells = 0;
                } else {
                    await sellToken(latestTokenBalance, slippage, address); // Wait for the sell to complete
                    setLastTransaction('sell');
                    consecutiveSells++;
                    consecutiveBuys = 0;
                }
            }
        } catch (error) {
            console.error('Error during trade execution:', error);
            setOutput(prevOutput => prevOutput + `Error during trade execution: ${error.message}\n`);
        } finally {
            setIsTradeInProgress(false); // Indicate that the trade is complete
        }
    };
    
    

    return (
        <div className="trader">
            <div className="button-container">
                <button onClick={handleStart} disabled={isRunning}>Start</button>
                <button onClick={handleStop} disabled={!isRunning}>Stop</button>
            </div>
            {/* <div className="balance">
                Balance: {balance ? balance.toFixed(2) : '0.00'} SOL
            </div>

            <div className="token-balance">
                Token Balance: {(tokenBalance || 0).toFixed(2)} {tokenSymbol || 'N/A'}
            </div>
            <div className="metrics">
                <div>Total SOL Spent: {totalSolSpent ? totalSolSpent.toFixed(2) : '0.00'}</div>
                <div>Total SOL Earned: {totalSolEarned ? totalSolEarned.toFixed(2) : '0.00'}</div>
                <div>Total Tokens Bought: {totalTokensBought ? totalTokensBought.toFixed(2) : '0.00'}</div>
                <div>Total Tokens Sold: {totalTokensSold ? totalTokensSold.toFixed(2) : '0.00'}</div>
                <div>Total Token Buys: {totalTokenBuys ? totalTokenBuys : 'N/A'}</div>
                <div>Total Token Sells: {totalTokenSells ? totalTokenSells : 'N/A'}</div>
            </div> */}
        </div>
    );
};

export default Trader;
