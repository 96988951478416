import React, { createContext, useMemo } from 'react';
import {
    ConnectionProvider,
    WalletProvider
} from '@solana/wallet-adapter-react';
import {
    WalletModalProvider,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import {
    PhantomWalletAdapter,
    CoinbaseWalletAdapter,
    SolflareWalletAdapter,
    AlphaWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import '@solana/wallet-adapter-react-ui/styles.css';

export const WalletContext = createContext();

export const WalletContextProvider = ({ children }) => {
    const endpoint = process.env.REACT_APP_CHAINSTACK_RPC; // Your custom RPC URL

    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new CoinbaseWalletAdapter(),
            new SolflareWalletAdapter(),
            new AlphaWalletAdapter(),
        ],
        []
    );

    if (!endpoint) {
        console.error("RPC endpoint is not set. Please set REACT_APP_CHAINSTACK_RPC in your .env file.");
        return null; // You might want to handle this differently in your application
    }

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    {children}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};
