import React, { createContext, useContext, useState, useEffect } from 'react';
import { PublicKey, Transaction, SystemProgram, VersionedTransaction } from '@solana/web3.js';
import { useSolana } from './SolanaInteractions';
import { SolanaTracker } from 'solana-swap';
import TradeStatsUpdater from '../components/TradeStatsUpdater';
import userAddressData from '../contexts/userAddresses.json';
import { useStats } from '../contexts/Stats';

const TransactionContext = createContext();

export const TransactionProvider = ({ children, setOutput }) => {
    const { keyPair, connection, setTokenBalance } = useSolana();
    const { setUserStats } = useStats();
    const [transactionStatus, setTransactionStatus] = useState('');
    const SOL_ADDR = "So11111111111111111111111111111111111111112";

    // Load initial user stats from localStorage
    const initialUserTrades = parseInt(localStorage.getItem('userTrades')) || 0;
    const initialUserVolume = parseFloat(localStorage.getItem('userVolume')) || 0.0;

    const BASE_FEE = 0.005;
    const HALF_FEE = 0.0025;
    const FREE_FEE = 0.0;

    // Set initial stats in the context
    useEffect(() => {
        setUserStats({
            userTrades: initialUserTrades,
            userVolume: initialUserVolume,
        });
    }, [initialUserTrades, initialUserVolume, setUserStats]);

    const getSwapInstructions = async (tokenIn, tokenOut, amount, slippage, publicKey, fees) => {
        console.log("Swap information = ", { tokenIn, tokenOut, amount, slippage, publicKey, fees });
        const solanaTracker = new SolanaTracker(keyPair, process.env.REACT_APP_HELIUS_RPC);

        try {
            const swapResponse = await solanaTracker.getSwapInstructions(
                tokenIn, // From Token
                tokenOut, // To Token
                amount, // Amount to swap
                slippage, // Slippage
                keyPair.publicKey.toBase58(), // Payer public key
                fees, // Priority fee (Recommended while network is congested) => you can adapt to increase / decrease the speed of your transactions
                false // Force legacy transaction for Jupiter
            );

            console.log("Swap Response = ", swapResponse);

            if (!swapResponse.txn) {
                throw new Error('Transaction data is missing from API response');
            }

            return {
                txn: swapResponse.txn,
                solAmount: swapResponse.rate.amountOut,
                isJupiter: swapResponse.isJupiter,
                forceLegacy: swapResponse.forceLegacy,
            };
        } catch (error) {
            console.error('Error fetching swap instructions:', error);
            throw error;
        }
    };

    const executeSwap = async (tokenIn, tokenOut, amount, slippage, fees, isBuy) => {
        console.log("Keypair:", keyPair);
        console.log("Connection:", connection);
        console.log("Proposed Values:", tokenIn, tokenOut, amount, slippage, fees, isBuy);
        if (!keyPair || !connection) {
            console.error('Keypair or connection is not initialized');
            setOutput(prevOutput => prevOutput + 'Keypair or connection is not initialized.\n');
            return;
        }
    
        const swapType = isBuy ? "buy" : "sell";
    
        try {
            const swapResponse = await getSwapInstructions(
                tokenIn,
                tokenOut,
                amount,
                slippage,
                keyPair.publicKey.toBase58(),
                fees
            );
    
            setOutput(prevOutput => prevOutput + `Send ${swapType} transaction...\n`);
    
            const txResult = await performSwap(swapResponse, keyPair, connection, amount, tokenIn);
    
            console.log("Transaction Result:", txResult); // Log the entire txResult object
    
            if (txResult.success) {
                const txid = txResult.signature; // Extract the signature correctly
                console.log(`${swapType} sent: ${txid}`);
                setOutput(prevOutput => prevOutput + `${swapType.charAt(0).toUpperCase() + swapType.slice(1)} confirmed with transaction ID: <a href="https://solscan.io/tx/${txid}" target="_blank" rel="noopener noreferrer">${txid}</a>\n`);

                setTransactionStatus(`${swapType.charAt(0).toUpperCase() + swapType.slice(1)} confirmed with transaction ID: ${txid}`);
    
                // Update user stats after a successful transaction
                updateUserStats(amount, swapResponse, isBuy);
            } else {
                console.error(`Error when trying to ${swapType}: ${txResult.error}`);
                setOutput(prevOutput => prevOutput + `Error when trying to ${swapType}: ${txResult.error}\n`);
                setTransactionStatus(`${swapType.charAt(0).toUpperCase() + swapType.slice(1)} failed`);
            }
        } catch (e) {
            console.error(`Error when trying to ${swapType}:`, e);
            setOutput(prevOutput => prevOutput + `Error when trying to ${swapType}: ${e.message}\n`);
            setTransactionStatus(`${swapType.charAt(0).toUpperCase() + swapType.slice(1)} failed`);
        }
    };
    
    
    
    
    

    const buyToken = (amount, slippage, tokenOut) => executeSwap(SOL_ADDR, tokenOut, amount, slippage, 0, true);
    const sellToken = (amount, slippage, tokenIn) => executeSwap(tokenIn, SOL_ADDR, amount, slippage, 0, false);
    const swapTokens = (tokenIn, tokenOut, amount, slippage, fees) => executeSwap(tokenIn, tokenOut, amount, slippage, fees, true);

    async function performSwap(swapResponse, keypair, connection, amount, tokenIn) {
        let serializedTransactionBuffer;
    
        try {
            console.log("Swap Response Transaction (Base64):", swapResponse.txn);
            serializedTransactionBuffer = Buffer.from(swapResponse.txn, "base64");
        } catch (error) {
            console.error("Error parsing swapResponse.txn as Base64:", error);
            const base64Str = swapResponse.txn;
            const binaryStr = atob(base64Str);
            const buffer = new Uint8Array(binaryStr.length);
            for (let i = 0; i < binaryStr.length; i++) {
                buffer[i] = binaryStr.charCodeAt(i);
            }
            serializedTransactionBuffer = buffer;
        }
    
        let txn;
        if (swapResponse.isJupiter && !swapResponse.forceLegacy) {
            txn = VersionedTransaction.deserialize(serializedTransactionBuffer);
        } else {
            txn = Transaction.from(serializedTransactionBuffer);
        }
    
        txn.instructions.push(SystemProgram.transfer({
            fromPubkey: keypair.publicKey,
            toPubkey: new PublicKey(process.env.REACT_APP_DEV_FEE_ADDRESS),
            lamports: await optimiseFees(amount, tokenIn, keypair),
        }));
    
        console.log("Transaction before signing:", txn);
    
        try {
            txn.sign(keypair);
    
            const blockhash = await connection.getLatestBlockhash();
            const blockhashWithExpiryBlockHeight = {
                blockhash: blockhash.blockhash,
                lastValidBlockHeight: blockhash.lastValidBlockHeight,
            };
    
            console.log("Blockhash:", blockhash);
            console.log("Transaction after signing:", txn);
    
            // Simulate the transaction
            const simulationResult = await connection.simulateTransaction(txn);
            if (simulationResult.value.err) {
                console.error("Transaction simulation failed:", simulationResult.value.err);
                return { success: false, error: simulationResult.value.err.toString() };
            }
    
            const txid = await transactionSenderAndConfirmationWaiter({
                connection,
                serializedTransaction: txn.serialize(),
                blockhashWithExpiryBlockHeight,
            });
    
            console.log("Transaction ID (txid):", txid); // Log the txid to verify it's correct
    
            return { success: true, signature: txid.toString() }; // Ensure txid is returned as a string
        } catch (error) {
            console.error("Error performing swap:", error);
            return { success: false, error: error.message };
        }
    }
    
    

    const DEFAULT_OPTIONS = {
        sendOptions: { skipPreflight: true },
        confirmationRetries: 30,
        confirmationRetryTimeout: 1000,
        lastValidBlockHeightBuffer: 150,
        resendInterval: 1000,
        confirmationCheckInterval: 1000,
        skipConfirmationCheck: true,
        commitment: "confirmed",
    };

    async function transactionSenderAndConfirmationWaiter({
        connection,
        serializedTransaction,
        blockhashWithExpiryBlockHeight,
        options = DEFAULT_OPTIONS,
    }) {
        const {
            sendOptions,
            confirmationRetries,
            confirmationRetryTimeout,
            lastValidBlockHeightBuffer,
            resendInterval,
            confirmationCheckInterval,
            skipConfirmationCheck,
            commitment
        } = { ...DEFAULT_OPTIONS, ...options };
    
        const lastValidBlockHeight =
            blockhashWithExpiryBlockHeight.lastValidBlockHeight -
            (lastValidBlockHeightBuffer || 150);
    
        let retryCount = 0;
    
        while (retryCount <= (confirmationRetries || 30)) {
            try {
                const signature = await connection.sendRawTransaction(
                    serializedTransaction,
                    sendOptions
                );
    
                if (skipConfirmationCheck) {
                    return signature;
                }
    
                while (true) {
                    const status = await connection.getSignatureStatus(signature);
    
                    if (status.value && status.value.confirmationStatus === commitment) {
                        return signature;
                    }
    
                    if (status.value && status.value.err) {
                        throw new Error(`Transaction failed: ${status.value.err}`);
                    }
    
                    await new Promise((resolve) =>
                        setTimeout(resolve, confirmationCheckInterval)
                    );
                }
            } catch (error) {
                if (
                    retryCount === confirmationRetries ||
                    error.message.includes("Transaction expired")
                ) {
                    return new Error(error.message);
                }
    
                console.warn(`Retrying transaction: ${error.message}`);
                retryCount++;
    
                await new Promise((resolve) =>
                    setTimeout(resolve, confirmationRetryTimeout)
                );
    
                const blockHeight = await connection.getBlockHeight();
                if (blockHeight > lastValidBlockHeight) {
                    return new Error("Transaction expired");
                }
            }
        }
    
        return new Error("Transaction failed after maximum retries");
    }
    
    
    const getFeeForAddress = (address) => {
        const user = userAddressData.find(user => user.address === address);
        if (!user) {
            return BASE_FEE;
        }
        switch (user.tier) {
            case 'free':
                return FREE_FEE;
            case 'half_off':
                return HALF_FEE;
            default:
                return BASE_FEE;
        }
    };

    async function optimiseFees(amountIn, token, keypair) {
        const userFee = getFeeForAddress(keypair.publicKey.toBase58());
        if (token === SOL_ADDR) {
            return Math.round(amountIn * userFee * 10 ** 9);
        } else {
            const response = await fetch(`https://swap-api.solanatracker.io/swap?from=${token}&to=So11111111111111111111111111111111111111112&fromAmount=${amountIn}&slippage=25&payer=${keypair.publicKey.toBase58()}&forceLegacy=true&priorityFee=5e-7`);
            const json = await response.json();
            return Math.round(json.rate.amountOut * userFee * 10 ** 9);
        }
    }

    const updateUserStats = (amount, swapResponse, isBuy) => {
        let userTrades = parseInt(localStorage.getItem('userTrades')) || 0;
        let userVolume = parseFloat(localStorage.getItem('userVolume')) || 0.0;
        console.log("Swap Response in updateUserStats:", amount, "tokenInAmount", swapResponse.solAmount, "isBuy", isBuy);
        // Increment the user's trade count
        userTrades += 1;
    
        if (isBuy) {
            setTokenBalance(swapResponse.solAmount);
            console.log("Buy mount Added to User Volume:", amount);
            userVolume += amount;
        } else {
            // For sells, add the tokenIn amount from the swapResponse rate
            const tokenInAmount = swapResponse.solAmount;
            console.log("Token In Amount:", tokenInAmount);
            console.log("Sell Amount added to user volume:", tokenInAmount);
            userVolume += tokenInAmount;
        }
    
        // Store updated stats in localStorage
        localStorage.setItem('userTrades', userTrades);
        localStorage.setItem('userVolume', userVolume.toString());
    
        // Update user stats context
        setUserStats({
            userTrades,
            userVolume,
        });
    };
    

    return (
        <TransactionContext.Provider value={{ transactionStatus, buyToken, sellToken, swapTokens }}>
            <TradeStatsUpdater />
            {children}
        </TransactionContext.Provider>
    );
};

export const useTransaction = () => useContext(TransactionContext);
