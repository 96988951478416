import React from 'react';


const AcceptDialog = ({ onAccept }) => {
    const handleAccept = () => {
        localStorage.setItem('accepted', 'true');
        onAccept();
    };

    return (
        <div className="accept-dialog">
            <h1>Please Read Carefully Before Continuing</h1>
            <p>Welcome to PumpNow.Fun, a bump bot for Pump.fun. This tool helps you stay at the top of Pump.fun.</p>
            <p>- For your security, please create a NEW wallet as we require your private key to operate. -</p>
            <p>- We charge a 0.5% fee per trade. -</p>
            <p>- By clicking 'Enter' you agree to the above. -</p>
            <button onClick={handleAccept}>Enter</button>
        </div>
    );
};

export default AcceptDialog;
